import { useRedirect, wrapKeyboardEvent } from '@salescore/frontend-common'
import { type KeyboardEvent, useEffect, useState } from 'react'

import { type LauncherCommandType, type LauncherCommandV2, useLauncherCommands } from './useLauncherCommands'

const useLauncher = () => {
  const [visibility, setVisibility] = useState(false)
  const [filterType, setFilterType] = useState<LauncherCommandType>('all')

  return {
    visibility,
    filterType,
    setFilterType,
    moveNextFilter: (option?: { reverse?: boolean }) => {
      const filtersRaw: Array<LauncherCommandType | undefined> = ['all', 'sheet', 'kpiPivot', 'page']
      const filters = filtersRaw.compact()
      const currentFilterIndex = filters.indexOf(filterType)
      const d = option?.reverse === true ? filters.length - 1 : 1
      const nextFilter = filters[(currentFilterIndex + d) % filters.length] ?? `all`
      setFilterType(nextFilter)
    },
    open: (option?: { filterType?: LauncherCommandType }) => {
      setVisibility(true)
      if (option?.filterType !== undefined) {
        setFilterType(option.filterType)
      }
    },
    hide: () => {
      setVisibility(false)
    },
  }
}

export const useLauncherState = () => {
  const redirect = useRedirect()
  const launcher = useLauncher()
  const [searchKey, setSearchKey] = useState('')
  const [cursor, setCursor] = useState(0)
  const { filterType, moveNextFilter, hide } = launcher
  const commands = useLauncherCommands({
    redirect,
  })
  const lowerCaseSearchKeys = searchKey.toLowerCase().split(/\s/)
  const filteredCommands = commands
    .filter((x) => lowerCaseSearchKeys.every((key) => [x.searchKeyword ?? '', x.title].join(' ').includes(key)))
    .filter((x) => filterType === 'all' || x.type === filterType)
    // .mySortBy((x) => Math.max(x.title.indexOf(lowerCaseSearchKey), x.searchKeyword?.indexOf(lowerCaseSearchKey) ?? -1))
    .slice(0, 30)

  const executeCommand = (command: LauncherCommandV2 | undefined): void => {
    if (command === undefined) {
      return
    }

    void command.method()
    if (command.options?.hideModalAfterExecution !== false) {
      hide()
    }
  }

  const moveDown = (): void => {
    setCursor((x) => {
      // 最下段にいるとき
      if (x === filteredCommands.length - 1) {
        return 0
      }
      return x + 1
    })
  }
  const moveUp = (): void => {
    setCursor((x) => {
      // 最上段にいるとき
      if (x === 0) {
        return filteredCommands.length - 1
      }
      return x - 1
    })
  }

  // eslint-disable-next-line complexity
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    const wrapped = wrapKeyboardEvent(event)
    switch (event.key) {
      case 'Enter': {
        const selectedCommand = filteredCommands[cursor]
        executeCommand(selectedCommand)
        event.preventDefault()
        event.stopPropagation()
        return
      }
      case 'ArrowDown': {
        moveDown()
        event.preventDefault()
        event.stopPropagation()
        return
      }
      case 'ArrowUp': {
        moveUp()
        event.preventDefault()
        event.stopPropagation()
        return
      }
      case 'Tab': {
        if (event.shiftKey) {
          moveNextFilter({ reverse: true })
        } else {
          moveNextFilter()
        }
        event.preventDefault()
        event.stopPropagation()
        return
      }
    }

    if (wrapped.macCtrlKey) {
      switch (event.key) {
        case 'n': {
          moveDown()
          event.preventDefault()
          event.stopPropagation()
          return
        }
        case 'p': {
          moveUp()
          event.preventDefault()
          event.stopPropagation()
          return
        }
      }
    }

    // TODO: あまり厳密に判定していない
    if (wrapped.commandKey) {
      const metaCommands = filteredCommands.filter((x) => x.shortcut?.includes('command'))
      const selectedCommand = metaCommands.find((x) => x.shortcut?.includes(event.key))
      if (selectedCommand !== undefined) {
        executeCommand(selectedCommand)
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }

  useEffect(() => {
    setCursor(0)
  }, [searchKey])

  return {
    launcher,
    searchKey,
    filteredCommands,
    cursor,
    setSearchKey,
    handleKeyDown,
    executeCommand,
    setCursor,
  }
}
